<template>
    <div style="background-color: #F2F2F2;">
        <div style="border: 1px solid red;width: 100%; position: relative;">
            <div style="
                        height: 11rem;
                        position: sticky;
                        top: 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;">
                <div style="display: flex; align-items: center;">
                    <img src="@/assets/zhengshu.png" style="height: 3.8rem; margin-left: 2rem; margin-right: 1rem;"/>
                    <span style="font-size: 2.5rem;">My Certificates</span>
                </div>
                <div style=" display: flex; margin-right: 6.8rem; align-items: center;">
                    <span style=" margin-right: 2rem; font-family: Arial, Helvetica, sans-serif;">Login</span>
                    <div style="background-color: #A4D7C7; height: 3.6rem; width: 10rem; text-align: center; line-height: 3.6rem; border-radius: 1.5rem; color: #F2F2F2; ">Sign up</div>
                </div>
            </div>
            <div style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        ">
                <!-- <canvas ref="certificates_canvas" width="1200" height="600" style="border: 1px solid gray;"></canvas> -->
                 <div style="background-color: #A4D7C7; width: 90%; height: 112rem; border-radius: 2rem;"></div>
                 <div style=" width: 76rem; height: 98rem; position: absolute; top: 16rem; background-color: #FCFCFC;box-shadow: 0 0 3rem 2rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" width: 38rem; height: 49rem; position: absolute; top: 28rem; left: 38rem; background-color: #FCFCFC;box-shadow: 0 0 3rem 2rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" width: 19rem; height: 26rem; position: absolute; top: 9rem; right: 48rem; background-color: #FCFCFC;box-shadow: 0 0 3rem 2rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" width: 48rem; height: 62rem; position: absolute; top: 38rem; right: 8rem; background-color: #FCFCFC;box-shadow: 0 0 3rem 2rem rgba(0, 0, 0, 0.2);"></div>
                 <div style=" width: 62rem; height: 48rem; position: absolute; bottom: -10rem; right: 48rem; background-color: #FCFCFC;box-shadow: 0 0 3rem 2rem rgba(0, 0, 0, 0.2);"></div>
                 <!-- <div style=" width: 62rem; height: 48rem; position: absolute; bottom: -5rem; left: -10rem; background-color: #FCFCFC;box-shadow: 0 0 3rem 2rem rgba(0, 0, 0, 0.2);"></div> -->
            </div>
        </div>
        <div style="border: 1px solid red;width: 100%;">
            <div style="
                        height: 11rem;
                        position: sticky;
                        top: 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;">
                <div style="display: flex; align-items: center;">
                    <img src="@/assets/zhengshu.png" style="height: 3.8rem; margin-left: 2rem; margin-right: 1rem;"/>
                    <span style="font-size: 2.5rem;">My Certificates</span>
                </div>
                <div style=" display: flex; margin-right: 6.8rem; align-items: center;">
                    <span style=" margin-right: 2rem; font-family: Arial, Helvetica, sans-serif;">Login</span>
                    <div style="background-color: #A4D7C7; height: 3.6rem; width: 10rem; text-align: center; line-height: 3.6rem; border-radius: 1.5rem; color: #F2F2F2;">Sign up</div>
                </div>
            </div>
            <div style="
                        display: flex;
                        justify-content: center;
                        align-items: center;">
                <canvas ref="certificates_canvas" width="1200" height="600" style="border: 1px solid gray;"></canvas>
                 <!-- <div style="background-color: #A4D7C7; width: 90%; height: 100rem;"></div> -->
            </div>
        </div>
        <div class="dalei">
            <div class="biaoti">
                projects i have worked on
            </div>
            <div class="description">
                111
            </div>
        </div>
        <div class="dalei">
            <div class="biaoti">
                my partners
            </div>
            <div class="description">
                111
            </div>
        </div>
        <div class="dalei">
            <div class="biaoti">
                mylife
            </div>
            <div class="description">
                111
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default{
        name:'Content',
        data(){
            return{

            }
        },
        methods:{
            drawCertificatesCanvas(){
                const canvas = this.$refs.certificates_canvas
                const ctx = canvas.getContext('2d')

                
                ctx.beginPath()
                ctx.moveTo(0, 0)
                ctx.lineTo(720, 0)
                ctx.lineTo(720, 300)
                ctx.lineTo(0, 500)
                ctx.closePath()
                ctx.fillStyle = '#7B9D7C'
                ctx.fill()
                ctx.strokeStyle = '#000000'
                ctx.lineWidth = 2
                ctx.stroke()


                ctx.beginPath()
                ctx.moveTo(720, 0)
                ctx.lineTo(1200, 0)
                ctx.lineTo(1200, 300)
                ctx.lineTo(720, 300)
                ctx.closePath()
                ctx.fillStyle = '#75ACA6'
                ctx.fill()
                ctx.strokeStyle = '#000000'
                ctx.lineWidth = 2
                ctx.stroke()
            }
        },
        mounted(){
            this.drawCertificatesCanvas()
        }
    }
</script>
<style scoped>
    .dalei{

    }

    .description{
        
    }
</style>