<template>
  <div >
    <Content/>
    <List/>
    <Footer/>
  </div>
</template>

<script>
import List from './components/List.vue'
import Footer from './components/Footer.vue'
import Content from './components/Content.vue';
export default {
  name: 'App',
  components:{Content, List, Footer}
}
</script>

