<template>
    <div style="display: flex; justify-content: center; align-items: center;">
        <span style="margin-right: 2rem;">捷对帅</span>
        <a href="https://beian.miit.gov.cn" target="_blank" style="margin-right: 2rem;">闽ICP备2024074958号-1</a>
        <img src="../assets/beiantubiao.png" style="width: 1.5rem; height: 1.5rem; margin-right: 2px;">
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=35011102351045" rel="noreferrer" target="_blank">闽公网安备35011102351045</a>
    </div>
</template>
<script>
    export default{
        name:'Footer',
        data(){
            return{

            }
        },
        methods:{

        },
        mounted(){
        }
    }
</script>
<style scoped>

</style>